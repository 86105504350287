import React from 'react'
import './hero.css'
import { Container, Button } from 'reactstrap'
import img from '../../assets/img/custom/img-1.jpg'
import { Link } from 'react-router-dom'

export default function Hero() {

  return (
    <Container 
        fluid
        id='hero'
        style={{backgroundImage: `url(${img})`}}
        className='d-flex align-items-center'
      >
        <div id='hero-overlay' className='d-flex align-items-center'>
            <Container align='center'>
                <h1 className='bold text-white' id='hero-text'>Sell Your Home Quickly Without the Hassle of Traditional Cash Offers</h1>
                <h2 className='text-white' id='hero-subtext'>We will pay full price for your home!</h2>
                <a href="#contact">
                  <Button>Get Started Now</Button>
                </a>
            </Container>
        </div>
    </Container>
  )
}
