import React, { useEffect } from "react";
import { CardBody, CardHeader, Container, Row, Col, Button, ListGroupItem } from "reactstrap";
import { UserAuth } from "contexts/AuthContext";
import { Link } from "react-router-dom";
import Hero from "components/Hero/Hero";
import HomeNav from "components/Navbars/HomeNav";
import PropertyList from "components/PropertyList/PropertyList";
import HomeFooter from "components/Footer/HomeFooter";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAnalytics } from "contexts/AnalyticsContext.js";
import "./index.css";
import ReviewList from "components/ReviewList/ReviewList";
import ContactForm from "components/ContactForm/ContactForm"
import ComparisonTable from "components/ComparisonTable/ComparisonTable";
import {Helmet} from "react-helmet";


export default function Home() {
  const { currentUser } = UserAuth();
  const { logEventAndStoreInFirestore } = useAnalytics();


  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "Home page visited");

    // check to see if user is logged in
    if (currentUser) {
      return;
    }
    logEventAndStoreInFirestore("Home page visited");
  }, []);

  const benefits = [
    {
      benefit: "Unique Selling Options Beyond Cash Offers",
      text: "We offer innovative alternatives to traditional cash purchases, providing homeowners with flexible and creative selling solutions.",
      icon: <i className="tim-icons icon-zoom-split" />,
    },
    {
      benefit: "Tailored Solutions for Homeowners in Unique Situations",
      text: "Our approach is customized to each homeowner's specific needs, ensuring personalized solutions for even the most complex selling scenarios.",
      icon: <i className="tim-icons icon-shape-star" />,
    },
    {
      benefit: "Expert Guidance in Non-Traditional Transactions",
      text: "Benefit from our deep expertise in navigating non-traditional transactions like subject-to and owner financing, backed by years of industry experience.",
      icon: <i className="tim-icons icon-single-02" />,
    },
  ];

  const explaination = [
    {
      benefit: "What is a 'Subject-To' Offer?",
      for: "Homeowners WITH a mortgage.",
      text: "A 'Subject-To' offer allows you to sell your home while keeping your existing mortgage in place. This means the we takes over the mortgage payments, but the loan remains in your name. This option is ideal if you're behind on payments, facing foreclosure, or simply need to sell quickly without the hassle of a traditional sale.",
      list: [
        "Avoid foreclosure and save your credit score",
        "Quick and hassle-free transaction",
        "No need to qualify for a new loan",
        "Potential for a higher sale price compared to a traditional cash offer"
      ],
      icon: <i className="tim-icons icon-zoom-split" />,
    },
    {
      benefit: "What is Seller Financing?",
      for: "Homeowners WITHOUT a mortgage.",
      text: "Seller financing, also known as owner financing, allows you to sell your home by acting as the lender. You finance our purchase, and they we make monthly payments to you instead of a bank. This option can be beneficial if you own your home outright or have significant equity.",
      list: [
        "Sell your home faster and often at a higher price",
        "Generate steady monthly income",
        "Potentially lower taxes compared to a lump-sum payment",
        "Flexibility in negotiating terms"
      ],
      icon: <i className="tim-icons icon-shape-star" />,
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KD Realty Acquisitions || We Buy Homes!</title>
        <link rel="canonical" href="http://www.kdrealtyacquisitions.com/" />
      </Helmet>
      <HomeNav />
      <Hero />
      <Container id="landing-page-body" fluid align='center' className="pb-5">
        <Container className="section-divider pt-5" fluid>
          <h2 className="section-divider-text">
          Having Trouble Selling Your Property?
          </h2>
          <h4>We specialize in providing a range of real
                  estate solutions tailored to meet your unique needs and
                  objectives. Our team of investors will buy your home regardless
                  of the condition, mortgage, or price. We are invested in helping
                  homeowners in their efforts to sell their home regardless of their
                  situation.</h4>
        </Container>

        <Container className="section-divider" fluid>
          <Row>
            {explaination.map((e) => {
              return (
                <Col xs="12" lg="6" align="center">
                  <div style={{ maxWidth: "35rem" }} className='mx-auto'>
                    <CardHeader style={{background: 'none'}}>
                      <span style={{fontSize: '30px', color: 'black'}}>{e.icon}</span>
                    </CardHeader>
                    <div className="mb-0 pb-0 pt-3">
                      <h3 className="card-header-text pb-0 mb-2">{e.benefit}</h3>
                      <h5 className="p-0 mb-4"><span style={{ fontWeight: 'bolder', fontStyle: 'italic', textDecoration: 'underline' }}>For {e.for}</span></h5>
                    </div>
                    <CardBody className="mt-0 pt-0">
                      {e.text}
                      <br />
                      <br />
                      <div style={{maxWidth: '400px'}}>
                      {e.list.map((i) => {
                        return(
                          <ListGroupItem>{i}</ListGroupItem>
                        )
                      })}
                      </div>
                    </CardBody>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>

        <div>
          <ComparisonTable />
        </div>

        <Container className="section-divider mt-5" fluid>
          <h2 className="section-divider-text">
            Bottomline, we will buy your home no matter the situation.
          </h2>
        </Container>

      </Container>

      <div align='center'>
          <div id="outro-section">
            <div id="outro-section-overlay">
              <Container>
                <h2 id="outro-section-text">Get a Free Consultation Today!</h2>
                <a href="#contact">
                  <Button>Get Started</Button>
                </a>
              </Container>
            </div>
          </div>
        </div>
        <div align="center" id='contact'>
          <ContactForm />
        </div>
      <HomeFooter />
    </>
  );
}
