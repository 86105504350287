import React, { useState, useRef } from 'react'
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardBody,
    Spinner,
    Container,
    Alert,
    Row,
    Col
  } from "reactstrap";
import { UserAuth } from 'contexts/AuthContext'

import { useNavigate } from 'react-router-dom';
import { TemplateContextData } from 'contexts/TemplateSettingsContext';
import './signin.css'
import HomeNav from 'components/Navbars/HomeNav';

export default function SignIn() {
  const { currentUser, signin } = UserAuth();

  const { templateState } = TemplateContextData();


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault()

    setLoading(true)

    if(currentUser) {
        setError("You're already logged in.")
        return
    }

    // register try
    try {
        await signin(email, password)
        navigate('/admin')
    } catch (error) {
        setError('Failed to log in. Please try again.')
        console.log(error)
    }

    setLoading(false)
   }

  return (
    <>
    <HomeNav />
    <Container  id="signin-container" style={{backgroundImage: `url(${templateState.signinBannerImage})`}} fluid className="align-items-center justify-content-center d-flex p-0">
        <Container id='signin-overlay' fluid className='p-0 h-100'></Container>
    <Card id="signin-card">
        {error ? <Alert color="danger">{error}</Alert> : <></>}
        <CardBody>
            <div>
                <h4 style={{color: 'white'}}>Admin Sign In</h4>
            </div>
            <form onSubmit={login} id="sigin-form">
                <FormGroup>
                    <Label for="Email" style={{color: 'white'}}>Email address</Label>
                    <Input
                    style={{color: 'white'}}
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="Enter email"
                            onChange={(e) => {setEmail(e.target.value)}}
                            />
                            <p style={{fontSize: '11px', color: 'grey'}}>Demo Login Email: test@test.com</p>

                </FormGroup>
                <FormGroup>
                    <Label for="Password" style={{color: 'white'}}>Password</Label>
                    <Input
                    style={{color: 'white'}}
                            type="password"
                            name="password"
                            id="examplePassword"
                            placeholder="Password"
                            autoComplete="off"
                            onChange={(e) => {setPassword(e.target.value)}}
                            />
                                            <p style={{fontSize: '11px', color: 'grey'}}>Demo Login Password: 1234567</p>

                </FormGroup>
                {loading ? 
                <>
                <Spinner color="primary">
                    Loading...
                </Spinner>
                </>
                :
                <>
                <Button color="primary" type="submit">
                   Submit
                </Button>
                </>
                }
            </form>
            </CardBody>
        </Card>
    </Container>
    </>
  )
}
