import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/img/custom/logo.png"
import {
  Collapse,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarText,
  Button,
  Container,
} from 'reactstrap';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './nav.css';

function HomeNav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{background: 'black'}} className='w-100 pt-2 pb-2'>
    <Navbar style={{background: 'black'}} collapseOnSelect expand="lg" className="bg-body-tertiary pt-2 pb-2 pr-0 pl-0">
      <Container fluid className='p-0'>
        <div style={{marginRight: 'auto', marginLeft: 'auto'}}>
          <Link className='nav-link' to='/'>
            <img src={logo} height={'75px'} width={"auto"} style={{ objectFit: 'contain' }} />
          </Link>
        </div>
      </Container>
    </Navbar>
    </div>
  );
}

export default HomeNav;