import React from 'react'
import {
  Container,
    Navbar,
    NavbarBrand,
  } from 'reactstrap';
import logo from "../../assets/img/custom/logo.png"

export default function HomeFooter() {
  return (
    <Navbar
    className='pt-2 pb-2'
    style={{background: 'black', color: 'white', minHeight: "5rem"}}
  >
    <Container fluid className='d-flex' style={{ alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <img src={logo} height={'75px'} width={"auto"} style={{ objectFit: 'contain' }} />
      <div className="copyright mt-3" style={{ fontSize: '11px' }}>
          © 2024 Created by{" "}
          <a
          style={{textDecoration: 'underline'}}
            href="https://www.ddmwebdesigns.com"
            target="_blank"
          >
            DDM Web Designs
          </a>
        </div>
    </Container>
    
  </Navbar>
  )
}
