import React, { useEffect, useState } from "react";

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from "../firebase.js"

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.js"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container,
} from "reactstrap";

import { UserAuth } from "contexts/AuthContext";

function UserProfile() {
  const { currentUser, userDoc, setUserDoc } = UserAuth()
  const [loading, setLoading] = useState(false)
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const [updatedUserDoc, setUpdatedUserDoc] = useState({}); // Initialize empty userDoc
  const [showEditForm, setShowEditForm] = useState(false); // Initialize showEditForm state to false
  const [selectedImage, setSelectedImage] = useState(null);

  
  // Define state for social media fields
  const [socials, setSocials] = useState({
    facebook: '',
    instagram: '',
    tiktok: '',
    x: '',
    pinterest: '',
    youtube: '',
  });

  useEffect(() => {
    if (userDoc) {
      setSocials(userDoc.socials || {});
      setLoading(false);
    }
  }, [userDoc]);


  useEffect(() => {console.log(userDoc)}, [])

  if(!currentUser || !userDoc){
    return(
      <div className="content">
        <p>Loading...</p>
      </div>
    )
  }

  if(loading){
    return(
      <div className="content">
        <p>Loading...</p>
      </div>
    )
  }

  if(!userDoc){
    return(
      <div className="content">
        <p>No data available. Please refresh page to try again.</p>
      </div>
    )
  }

  // Update state when social media fields change
  const handleSocialsChange = (e, platform) => {
    const { value } = e.target;
    setSocials(prevState => ({
      ...prevState,
      [platform]: value,
    }));
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userDocRef = doc(db, 'user', currentUser.uid);
      // Update the userDoc with the new values
      const updatedDoc = {
        company: updatedUserDoc.company || userDoc.company,
        brokerage: updatedUserDoc.brokerage || userDoc.brokerage,
        realtorId: updatedUserDoc.realtorId || userDoc.realtorId,
        email: updatedUserDoc.email || userDoc.email,
        phoneNumber: updatedUserDoc.phoneNumber || userDoc.phoneNumber,
        firstName: updatedUserDoc.firstName || userDoc.firstName,
        lastName: updatedUserDoc.lastName || userDoc.lastName,
        city: updatedUserDoc.city || userDoc.city,
        state: updatedUserDoc.state || userDoc.state,
        aboutMe: updatedUserDoc.aboutMe || userDoc.aboutMe,
        user_image: updatedUserDoc.user_image || userDoc.user_image,
        socials: { ...socials }, // Include updated socials
      };
  
      await updateDoc(userDocRef, updatedDoc);
  
      // Update the userDoc state with the merged data
      const mergedUserDoc = { ...userDoc, ...updatedDoc };
      setUserDoc(mergedUserDoc);
  
      setUpdateProfileModal(false);
      setSelectedImage(null)
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
    setShowEditForm(false); // Hide the edit form after profile update
  };

  const statesList = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];


  return (
    <>
      <div className="content">
        {showEditForm ? 
          <>
            <Card>
            <Form onSubmit={handleProfileUpdate}>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                    <Row>
                    <Col className="px-md-2" md='6' lg="4">
                      <FormGroup>
                        <label>Company Name</label>
                        <Input
                          defaultValue={userDoc.company}
                          placeholder="Company"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, company: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="4">
                      <FormGroup>
                        <label>Brokerage</label>
                        <Input
                          defaultValue={userDoc.brokerage}
                          placeholder="Brokerage"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, brokerage: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="2">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue={userDoc.firstName}
                          placeholder="Company"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, firstName: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-2" md='6' lg="2">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue={userDoc.lastName}
                          placeholder="Last Name"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, lastName: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-2" xs='4' md='2' lg="1">
                      <FormGroup>
                        <label>Realtor ID</label>
                        <Input
                          defaultValue={userDoc.realtorId}
                          placeholder="Realtor ID"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, realtorId: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="2">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Contact Email
                        </label>
                        <Input 
                          placeholder={userDoc.email} 
                          defaultValue={userDoc.email}
                          type="email"
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" // Email pattern validation
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, email: e.target.value }))}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-2" md='6' lg="2">
                      <FormGroup>
                        <label htmlFor="exampleInputPhoneNumber">
                          Contact Phone #
                        </label>
                        <Input 
                          placeholder={userDoc.phoneNumber} 
                          defaultValue={userDoc.phoneNumber}
                          type="tel"
                    pattern="\(\d{3}\) \d{3}-\d{4}" // Enforce format (###) ###-####
                    maxLength="14" // Maximum characters for format (###) ###-####
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, phoneNumber: e.target.value }))}
                          />
                                            <p style={{fontSize: '11px', color: 'grey'}}>* Required format: (123) 456-7890</p>

                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className="px-md-2" xs='9' lg="2">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue={userDoc.city}
                          placeholder="City"
                          type="text"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, city: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-2" xs='2' lg="1">

                      <FormGroup>
          <label for="state">State</label>
          <Input
            type="select"
            name="state"
            placeholder="state"
            defaultValue={userDoc.state}
            onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, state: e.target.value }))}
            >
            <option value="">Select State</option>
            {statesList.map(state => (
              <option key={state.value} value={state.value}>{state.label}</option>
            ))}
          </Input>
        </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-2" lg="4">
                      <FormGroup>
                        <label>Bio</label>
                        <Input
                        style={{minHeight: '150px'}}
                          cols="100"
                          defaultValue={userDoc.aboutMe}
                          placeholder="Here can be your description 300 character max)"
                          rows="4"
                          maxLength={300}
                          type="textarea"
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, aboutMe: e.target.value.slice(0,300) }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>Facebook</label>
                        <Input
                          defaultValue={socials.facebook}
                          placeholder="Facebook url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'facebook')}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>Instagram</label>
                        <Input
                          defaultValue={socials.instagram}
                          placeholder="Instagram url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'instagram')}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>Tiktok</label>
                        <Input
                          defaultValue={socials.tiktok}
                          placeholder="Tiktok url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'tiktok')}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>X (Twitter)</label>
                        <Input
                          defaultValue={socials.x}
                          placeholder="X (Twitter) url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'x')}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>Pinterest</label>
                        <Input
                          defaultValue={socials.pinterest}
                          placeholder="Pinterest url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'pinterest')}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="px-md-2" md='6' lg="3">
                      <FormGroup>
                        <label>Youtube</label>
                        <Input
                          defaultValue={socials.youtube}
                          placeholder="Youtube url"
                          type="text"
                          onChange={(e) => handleSocialsChange(e, 'facebook')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col className="px-md-2" lg="12">
                    <div>
                        {userDoc.user_image && 
                          <>
                          <br />
                          <label>Current Image</label> 
                          <br />
                          <img src={userDoc.user_image} alt="User" style={{ width: '200px', height: '200px', objectFit: 'cover', marginBottom: '10px' }} />
                          </>
                        }
                        {selectedImage && (
                        <div>
                          <label>Preview</label>
                          <br />
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Preview"
                            style={{ width: '200px', height: '200px', objectFit: 'cover', marginBottom: '10px' }}
                          />
                        </div>
                      )}
                      </div>
                      <FormGroup>
                        <Button>Upload Image</Button>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            setSelectedImage(file); // Set the selected image file
                            if (file) {
                              const storageRef = ref(storage, `user/${currentUser.uid}`);
                              await uploadBytes(storageRef, file);
                              const imageUrl = await getDownloadURL(storageRef);
                              setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, user_image: imageUrl }));
                            }
                          }}
                        />
                        

                      </FormGroup>
                    </Col>
                  </Row>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="secondary" onClick={() => setShowEditForm(false)}>Cancel</Button> {/* Button to show the edit form */}
                <Button className="btn-fill ml-auto" color="primary" type="submit" >
                  Save
                </Button>
              </CardFooter>
              </Form>
            </Card>
          </> 
          : 
          <>
            <Container fluid>
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" style={{background: 'linear-gradient(to right, #212529 0%, rgba(0, 0, 0, 0) 100%)'}} />
                    <div className="block block-two" style={{background: 'linear-gradient(to right, #212529 0%, rgba(0, 0, 0, 0) 100%)'}} />
                    <div className="block block-three" style={{background: 'linear-gradient(to right, #212529 0%, rgba(0, 0, 0, 0) 100%)'}} />
                    <div className="block block-four" style={{background: 'linear-gradient(to right, #212529 0%, rgba(0, 0, 0, 0) 100%)'}} />
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {userDoc.user_image ? 
                      <>
                        <img
                        alt="..."
                        className="avatar"
                        src={userDoc.user_image}
                        style={{objectFit: 'cover'}}
                        />
                      </> 
                      : 
                      <>
                        <Container className="avatar d-flex justify-content-center align-items-center" style={{backgroundColor: 'black'}}>
                          <p>No Image</p>
                        </Container> 
                      </>
                      }
                      <h5 className="title">{userDoc.firstName}{" "}{userDoc.lastName}</h5>
                    </a>
                    <p className="description">{userDoc.title}</p>
                  </div>
                  <div className="card-description">{userDoc.aboutMe}</div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardText>
                    <p align='center'>{userDoc.company}</p>
                    <p align='center'>{userDoc.brokerage}</p>
                    <p align='center'>{userDoc.email}</p>
                    <p align='center'>{userDoc.realtorId}</p>
                  </CardText>
                </CardBody>
                <CardFooter>
                <div className="button-container" align='center'>
                  {userDoc.socials.facebook && (
                    <a href={userDoc.socials.facebook} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  </a>
                  )}
                  {userDoc.socials.instagram && (
                    <a href={userDoc.socials.instagram} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-instagram" />
                  </Button>
                  </a>
                  )}
                  {userDoc.socials.tiktok && (
                    <a href={userDoc.socials.tiktok} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-tiktok" />
                  </Button>
                  </a>
                  )}
                  {userDoc.socials.x && (
                    <a href={userDoc.socials.x} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-twitter" />
                  </Button>
                  </a>
                  )}
                  {userDoc.socials.youtube && (
                    <a href={userDoc.socials.youtube} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-youtube" />
                  </Button>
                  </a>
                  )}
                  {userDoc.socials.pinterest && (
                    <a href={userDoc.socials.pinterest} target='_blank'>
                    <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-pinterest" />
                  </Button>
                  </a>
                  )}
                  </div>
                  <div className='mt-2' align='center'>
                  <Button onClick={() => setShowEditForm(true)} className='ml-auto mr-auto'>Edit</Button> {/* Button to show the edit form */}
                  </div>
                </CardFooter>
              </Card>
            </Container>
          </>
        }
      </div>
    </>
  );
}

export default UserProfile;
