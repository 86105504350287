// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlL0h3li3sEauSOqne4zrjhzDkwdqpUuk",
  authDomain: "realtor-template-app.firebaseapp.com",
  projectId: "realtor-template-app",
  storageBucket: "realtor-template-app.appspot.com",
  messagingSenderId: "776511534488",
  appId: "1:776511534488:web:1df16f0901b06ac9f7a7ed",
  measurementId: "G-F3TMV2KD7V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
