import React from 'react';
import { Container, Table } from 'reactstrap';

const ComparisonTable = () => {
  return (
    <>
      <h2>Comparison Table</h2>
      <Table className="comparison-table" striped bordered hover style={{margin: 'auto auto', maxWidth: '1500px'}}>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Traditional Sale</th>
            <th>Subject-To</th>
            <th>Seller Financing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Speed of Sale</td>
            <td>Slow</td>
            <td>Fast</td>
            <td>Fast</td>
          </tr>
          <tr>
            <td>Mortgage Transfer</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Credit Impact</td>
            <td>High</td>
            <td>Low</td>
            <td>Low</td>
          </tr>
          <tr>
            <td>Monthly Income</td>
            <td>No</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Sale Price Potential</td>
            <td>Market-dependent</td>
            <td>Higher</td>
            <td>Higher</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ComparisonTable;
