import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Col,
  Row,
  Alert,
} from "reactstrap";
import emailjs from '@emailjs/browser';
import "./contact.css";

export default function Contact() {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [email, setEmail] = useState("");

  const [propertyAddress, setPropertyAddress] = useState("")
  const [propertyAddress2, setPropertyAddress2] = useState("")
  const [propertyCity, setPropertyCity] = useState("")
  const [propertyState, setPropertyState] = useState("")
  const [propertyZip, setPropertyZip] = useState(0)
  const [propertySellingReason, setPropertySellingReason] = useState("")


  const [message, setMessage] = useState({
    text: "",
    color: "",
  });
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if(!propertyAddress || !name || !email || phoneNumber === 0 || propertyZip === 0){
      setMessage({color: 'danger', text: "Missing required fields. Please try again."})
      handleMessageReset();
      return
    }
{/*
    // Define recipient emails
    const recipientEmails = ['hfsrealestate@gmail.com', 'dominikproperty@gmail.com'];
    const toEmail = recipientEmails.join(',');

    // Create form data
    const formData = new FormData(form.current);
    formData.append('to_email', toEmail);
*/}
    
    await emailjs.sendForm('service_1tt57aa', 'template_98kl2xw', form.current, {
      publicKey: 'ROjDrDn9IcjRZkwBc',
    })
      .then((result) => {
        setMessage({text: "Message sent successfully!", color: "success"})
        handleMessageReset();
        console.log(result)
    }, (error) => {
        setMessage({text: "Sorry there seems to be an error...please try again", color: 'danger'})
        setLoading(false)
        handleMessageReset();
        console.log(error)
    });

    handleReset();
  };

  useEffect(() => {console.log(`name:, ${name}`, `phone: ${phoneNumber}`, `email: ${email}`, `property: ${propertyAddress}`, `property secondary: ${propertyAddress2}`, `city: ${propertyCity}`, `state: ${propertyState}`, `zip: ${propertyZip}`, `reason: ${propertySellingReason}`)}, [name, phoneNumber, email, propertyAddress, propertyAddress2, propertyCity, propertyState, propertyZip, propertySellingReason])

  // handles resetting the form data state
  const handleReset = () => {
    setName("");
    setPhoneNumber(0);
    setEmail("");

    setPropertyAddress("");
    setPropertyAddress2("");
    setPropertyCity("");
    setPropertyState("");
    setPropertyZip(0)
    setPropertySellingReason("");

    setLoading(false);
  };

  // handles resetting message state
  const handleMessageReset = () => {
    setTimeout(() => {
      setMessage({
        text: "",
        color: "",
      })
    }, 3000)
  }

  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
    "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
    "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
    "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
    "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
    "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
    "Wisconsin", "Wyoming"
  ];
  

  return (
    <Container fluid id="contact">
      <div id="contact-card">
        <CardBody>
          <form className="p-3" onSubmit={handleSubmit} ref={form}>
            {message.text && (<Alert color={message.color}>{message.text}</Alert>)}
            <Row>
              
              <Col xs="12">
                <FormGroup>
                  <Label style={{ color: "black" }} for="nameInput">
                    Name
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    id="nameInput"
                    placeholder="Enter your name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs='12' md='6'>
              <FormGroup>
              <Label style={{ color: "black" }} for="emailInput">
                Email your address
              </Label>
              <Input
                type="email"
                name="email"
                value={email}
                id="emailInput"
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <FormText color="muted" style={{ fontSize: '11px' }}>
                We'll never share your email with anyone else.
              </FormText>
            </FormGroup>
              </Col>
              <Col xs='12' md='6'>
              <FormGroup>
              <Label style={{ color: "black" }} for="phoneNumberInput">
                Phone number
              </Label>
              <Input
                type="number"
                name="phoneNumber"
                value={phoneNumber}
                id="phoneNumberInput"
                placeholder="Enter your phone number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                required
              />
            </FormGroup>
              </Col>
            </Row>
            
            <div className="mt-2 mb-3" style={{ background: 'grey', height: '.25px', width: '70px', }}></div>

            <FormGroup>
              <Label style={{ color: "black" }} for="propertyInput">Property Address</Label>
              <Input
                id="propertyInput"
                name="propertyAddress"
                placeholder="Enter your property address"
                value={propertyAddress}
                onChange={(e) => {
                  setPropertyAddress(e.target.value);
                }}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label style={{ color: "black" }} for="propertyInput2">Property Address 2</Label>
              <Input
                id="propertyInput2"
                name="propertyAddress2"
                placeholder="Apartment, studio, or floor"
                value={propertyAddress2}
                onChange={(e) => {
                  setPropertyAddress2(e.target.value);
                }}
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label style={{ color: "black" }} for="propertyCityInput">City</Label>
                  <Input 
                    id="propertyCityInput"
                    name="propertyCity"
                    placeholder="Enter the city"
                    value={propertyCity}
                    onChange={(e) => {
                    setPropertyCity(e.target.value);
                    }} 
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={7} md={3}>
                <FormGroup>
                  <Label style={{ color: "black" }} for="propertyStateInput">State</Label>
                  <Input
                    type="select" 
                    id="propertyStateInput"
                    name="propertyState"
                    placeholder="Enter the state"
                    value={propertyState}
                    onChange={(e) => {
                    setPropertyState(e.target.value);
                    }} 
                    required
                  >
                    <option value="">Select a state</option>
          {states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
                    </Input>
                </FormGroup>
              </Col>
              <Col xs={5} md={3}>
                <FormGroup>
                  <Label style={{ color: "black" }} for="propertyZipInput">Zip</Label>
                  <Input 
                    type="number"
                    id="propertyZipInput"
                    name="propertyZip"
                    placeholder="Zipcode"
                    value={propertyZip}
                    onChange={(e) => {
                    setPropertyZip(e.target.value);
                    }} 
                    required
                   />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label style={{ color: 'black' }} for="propertySellingReasonInput">Reason for Selling</Label>
              <Input
                id="propertySellingReasonInput"
                name="propertySellingReason"
                type="textarea"
                value={propertySellingReason}
                onChange={(e) => {
                  setPropertySellingReason(e.target.value);
                }}
                required
              />
            </FormGroup>

            <Button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </CardBody>
      </div>
    </Container>
  );
}
