import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import Contacts from "views/Contacts";
import Properties from "views/Properties";
import Reviews from "views/Reviews";
import Support from "views/Support";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/my-properties",
    name: "Properties",
    icon: "tim-icons icon-components",
    component: <Properties />,
    layout: "/admin",
  },
  {
    path: "/contacts",
    name: "Contacts",
    icon: "tim-icons icon-book-bookmark",
    component: <Contacts />,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: "tim-icons icon-shape-star",
    component: <Reviews />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Support",
    icon: "tim-icons icon-chat-33",
    component: <Support />,
    layout: "/admin",
  },
];
export default routes;
